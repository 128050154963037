/* eslint-disable no-restricted-globals */
import { useState, ChangeEvent, FocusEvent, useEffect } from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  TextField,
  Checkbox,
  Button,
  MenuItem,
  FormControl,
  InputLabel,
  // Input,
  Select,
  Grid,
  Alert,
  CircularProgress
} from "@mui/material";
import { Check, WarningAmber } from "@mui/icons-material";
import Cards, { Focused } from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css'
import Loading from '../../components/Loading';

import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  validateCvc,
  validateCreditCardNumber,
  validateExpiry,
  encryptRsaWithPublicKey,
  decode,
} from "../../general/utils";
import { CardInformation, PaymentInformation, PaymentRequest, AxaCardInformation } from "../../models/paymentRequest";
interface initialS {
  number: string;
  name: string;
  cvc: string;
  expiry: string;
  focused: Focused;
}

interface infoGTM{
  costo_anual: number,
  modalidad_pago: string,
  plan: string,
  promo_code: string,
  quote_id: string
}

export const DataCard: React.FC = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("sm"));
  const isTablet = useMediaQuery(breakpoints.down("md"));
  const isDesktop = useMediaQuery(breakpoints.up("lg"));

  const interServicesurl = process.env.REACT_APP_SERVICE_AIG_URL || 'https://demos.inter.mx/v1/';

  const initialState: initialS = {
    number: '',
    name: '',
    cvc: '',
    expiry: '',
    focused: 'name',
  };

  const intialDataGTM: infoGTM = {
    costo_anual: 1500,
    modalidad_pago: 'uno',
    plan: 'dos',
    promo_code: 'tres',
    quote_id: 'cuatro'
  }

  const [infoCardValid, setInfoCardValid] = useState(true);
  const [cardValid, setCardValid] = useState(true);
  const [dataSend, setDataSend] = useState(false);
  const [expiryValid, setExpiryValid] = useState({
    valid: true,
    focused: false
  });
  const [dataPaymentGTM, setdataPaymentGTM] = useState(intialDataGTM);
  const [nameValid, setNameValid] = useState({
    valid: true,
    focused: false
  });
  const [cvcValid, setCvcValid] = useState({
    valid: true,
    focused: false
  });
  const [numberValid, setNumberValid] = useState({
    valid: true,
    focused: false
  });
  const [userIDValid, setuserIDValid] = useState({
    valid: true,
    focused: false
  });

  const [dataPaymentInitial, setDataPaymentInitial] = useState({
    quote: '',
    quoteId: '',
    emissionId: '',
    emission_id: '',
    tracking_id: '',
    paymentId: '',
    insurance: '',
    business: 'cocheseguro',
    payment_option: '',
    policy_id: '043190470536000000'
  });
  const [dataPaymentAditional, setdataPaymentAditional] = useState<any>({})
  const [redirectUrl, setRedirectUrl] = useState<string>("https://cocheseguro.inter.mx/datos-tarjeta");
  const [hidePlazos, setHidePlazos] = useState(true);
  const [ dataLayerInitial, setDataLayerInitial ] = useState({
    event: "datos_bancarios",
  });
  useEffect(() => {
    getAccessToken();
    const token = localStorage.getItem('token');
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let data = params.get('q');

    if(data){
      const { business } = JSON.parse(decode(data));
      changeNameEvent(business);
    }

    // AQUI SE INSERTARA EL PUSH AL EVENTO
    (window as any).dataLayer.push({
      event : dataLayerInitial.event,
      boton_pagar: "Ya Quiero Pagar",
      estatus_formulario: "Succesful"
    });
    // if (!token) {
    // }
    
    
    let redirectUrl = params.get('url');
    if(redirectUrl) {
      setRedirectUrl(redirectUrl);
    }
    if (!data) {
      // setInfoCardValid(false);
    } else {
      // const baseURL = process.env.REACT_APP_SERVICE_AIG_URL;
      console.log(JSON.parse(decode(data)));
      setDataPaymentInitial(JSON.parse(decode(data)));
      setdataPaymentAditional(JSON.parse(decode(data)));
      // const encrypted = encryptRsaWithPublicKey(decode(data));
      // console.log(encrypted);
    }
  }, [])

  const placeholders = {
    name: 'Nombre titular'
  }

  const [cardState, setCardState] = useState(initialState);
  const [paymentId, setPaymentId] = useState<string>('');
  const [cardNat, setCardNat] = useState<string>('');
  const [cardBrand, setCardBrand] = useState<string>('');
  const [brandId, setBrandId] = useState<string>('');
  const [bankId, setBankId] = useState<string>('');
  const [paymentAlert, setPaymentAlert] = useState<boolean>(false);
  const [paymentError, setPaymentError] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('Ha ocurrido un error en el pago.');
  const [bankInfo, setBankInfo] = useState<any>();
  const [checkedCard, setCheckedCard] = useState<string>('');
  const [payCoverage, setPayCoverage] = useState<string>('-');
  const [promotions, setPromotions] = useState<Array<any>>([
    {
      formaPago: 'contado',
      idPlazo: 1,
      labelPlazo: "-"
    }
  ]);
  const [userID, setuserID] = useState<string>('');
  const [notices, setNotices] = useState({
    confidentiality: false,
    privacy: false,
    noticeX: true,
    noticeY: true,
    noticeZ: true
  });
  const [typeCvc, setTypeCvc] = useState<string>('')
  const [typeCardNumberF, setTypeCardNumberF] = useState<string>('');
  const [paymentIsLoading, setPaymentisLoading] = useState<boolean>(false);
  const [promotionsLoading, setPromotionsLoading] = useState<boolean>(false);
  const [ visibleDigits, setVisibleDigits ] = useState('');

  const handleCardChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;
    if (name === "expiry") {
      value = formatExpirationDate(value);
    } else if (name === "cvc") {
      value = formatCVC(value);
      value.length >= 3 ? setTypeCvc('password') : setTypeCvc('text');
    } else if (name === "number") {
      setTypeCardNumberF('text');
      setPaymentError(false);
      setCardValid(false);
      value = formatCreditCardNumber(value);
      setTimeout(() => {
        setTypeCardNumberF('password');
      },1000);
      if(value.length >= 16){
        setVisibleDigits(value.slice(-4));
        handleNumberBlur();
      }
    } else if (name === "name") {
      const regexText = /^[a-zA-z\u00E0-\u00FC ]+$/;
      if (!regexText.test(value)){
       value = value.replace(/\d/g,"");
      }
    }
    setCardState({ 
        ...cardState,
        [name]: value 
    });
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    let { value } = event.target;
    setuserID(value);
  }

  const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let focus: Focused = 'name';
    if (name === 'number') {
      focus = 'number';
      setNumberValid({
        valid: true,
        focused: true
      })
    } else if (name === "expiry") {
      focus = 'expiry'
      setExpiryValid({
        valid: true,
        focused: true
      })
    } else if (name === 'cvc') {
      focus = 'cvc'
      setCvcValid({
        valid: true,
        focused: true
      })
    } else if (name === 'name') {
      focus = 'name'
      setNameValid({
        valid: true,
        focused: true
      })
    }
    setCardState({ 
        ...cardState,
        focused: focus
    });
  }

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    const name = event.target.name;
    let focus: Focused = 'name';
    if (name === "expiry") {
      setExpiryValid({
        valid: isValidExpiry(),
        focused: true
      })
    } else if (name === 'cvc') {
      setCvcValid({
        valid: isValidCvc(),
        focused: true
      })
    } else if (name === 'name') {
      setNameValid({
        valid: isValidName(),
        focused: true
      })
    }
    else if (name === 'email'){
      setuserIDValid({
        valid: isValidEmail(),
        focused: true
      });
    }
  }

  const handleChangePromotion = (event: any) => {
    const value = event.target.value;
    setPayCoverage(value);
  }

  const handleNumberBlur = () => {
    setTypeCardNumberF('text')
    // if (checkedCard === cardState.number) return;
    if (cardState.number.replaceAll(' ', '').length < 6 || !validateCreditCardNumber(cardState.number)) {
      setPromotionsLoading(false);
      setCardValid(false);
      setNumberValid({
        valid: false,
        focused: true
      });
      return;
    }

    infoDataGTM();

    if(promotionsLoading) return;
    setPromotionsLoading(true);
    setPaymentError(false);
    setCardValid(false);

    const url = `${interServicesurl}${dataPaymentInitial.business}/${dataPaymentInitial.insurance}/check-card`;

    const body = {
      business: dataPaymentInitial.business || "cocheseguro",
      bussiness: dataPaymentInitial.business || "cocheseguro",
      insurance: dataPaymentInitial.insurance,
      tracking_id: dataPaymentInitial.tracking_id,
      card_number: cardState.number.replaceAll(' ', ''),
      payment_id: dataPaymentInitial.paymentId,
      emission_id: dataPaymentInitial.emissionId,
      quote_id: dataPaymentInitial.quoteId,
      payment_option: dataPaymentInitial.payment_option,
      policy_id: dataPaymentInitial.policy_id
    };

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .then(response => {
      let msg = 'Error al validar tarjeta';
      if(!response.success){
        setPromotionsLoading(false);
        if(response.message){
          msg = response.message || 'Error al validar tarjeta';
        }
        setPaymentError(true);
        setErrorMsg(msg);
        setCardValid(false);
        setNumberValid({
          valid: false,
          focused: false
        })
        return;
      }
      setPromotionsLoading(false);
      if (!response.data) {
        console.error('Error:', response)
        if (response.message && typeof response.message === 'string' && response.statusCode !== 500) {
          msg = response.message;
        } else {
          setCardState({ 
            ...cardState,
            number: '' 
          });
        }
        setPaymentError(true);
        setErrorMsg(msg);
        setCardValid(false);
        setNumberValid({
          valid: false,
          focused: false
        })
        return;
      }
      infoDataGTM(response?.data?.cardType);
      if(dataPaymentInitial.insurance == 'axa') {
        setPromotions(response.data.promotion)
        setPayCoverage(response.data.option);
        setCardValid(true);
        setNumberValid({
          valid: true,
          focused: true
        })
  
        return
      };
      setPromotions(response.data?.bankTerms);
      if(response.data.bankTerms.length === 1){
        let event: any = { target: {value: ''}};
        event.target.value = response.data.bankTerms[0].labelPlazo;
        handleChangePromotion(event)
        if(response.data.bankTerms[0].labelPlazo.toLowerCase() === 'contado'){
          setHidePlazos(true);
        }
        else if(response.data.bankTerms[0].labelPlazo.toLowerCase() === 'mensual'){
          setHidePlazos(true);
        }
        else if(response.data.bankTerms[0].labelPlazo.toLowerCase() === 'anual'){
          setHidePlazos(true);
        }
        else{
          setHidePlazos(false);
        }
      }
      else{
        setPromotions(response.data?.bankTerms);
        setHidePlazos(false);
      }
      console.log("SE VALIDO LA TARJETA");
      setCheckedCard(cardState.number);
      setCardValid(true);
      setNumberValid({
        valid: true,
        focused: true
      })
      setPaymentId(response.data?.paymentId);
      setCardNat(response.data?.cardType);
      setCardBrand(response.data?.bankBrand);
      setBrandId(response.data?.brandId?.toString());
      setBankId(response.data?.bankID?.toString());
      setBankInfo(response.data);
      infoDataGTM(response.data?.cardType);
      setTypeCardNumberF('password');
    })
    .catch(error => {
      setPaymentError(true);
      setErrorMsg('Error al validar tarjeta');
      setPromotionsLoading(false);
      console.error('Error:', error)
    });
  }

  const infoDataGTM = (cardType?: string) => {
    console.log("OBTENIENDO INFO DATA GTM");
    const dataGTM = `${interServicesurl}dataPaymentGTM`;
    const body2 = {
      business: dataPaymentInitial.business || "cocheseguro",
      insurance: dataPaymentInitial.insurance,
      emissionId: dataPaymentInitial.emission_id || dataPaymentInitial.emissionId,
    };

    fetch(dataGTM, {
        method: 'POST',
        body: JSON.stringify(body2),  
        headers:{
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      }).then(res => res.json())
      .then(async (response) => {
        localStorage.setItem('paymentGM', JSON.stringify(response));
        (window as any).dataLayer.push({
          event : "Add_payment_info",
          value: response.costo_anual,
          coupon: response.promo_code,
          payment_type: cardType,
          item_id: response.quote_id,
          item_name: `Coche Seguro + ${dataPaymentInitial.insurance}`,
          price: response.costo_anual,
          quantity: "1"
        });
      })
      .catch(error => {
        console.log("ERROR GTM EVENT ----> ",error);
      });

  };

  const handleNameBlur = () => {
    if(dataPaymentInitial.insurance == 'axa'){
      let arrayNombre = cardState.name.split(' ');
      if(arrayNombre.length < 3){
        setPaymentError(true);
        setErrorMsg('El nombre debe contener NOMBRE APELLIDOPATERNO APELLIDOMATERNO');
        setNameValid({
          valid: false,
          focused: true
        });
        return;
      }
    }
    if(!/^[a-zA-Z\s]*$/.test(cardState.name.trim())){
      setPaymentError(true);
      setErrorMsg('El nombre no puede contener acentos, tilde (ñ) y diéresis (ü)');
      setNameValid({
        valid: false,
        focused: true
      });
      return;
    }
    setNameValid({
        valid: true,
        focused: true
      });
    setPaymentError(false);
    console.log("Nombre valido");
  };

  const handleEmailBlur = () => {
    if(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userID)){
      setPaymentError(true);
      setErrorMsg('Ingrese una direccion de correo electronico valido');
      setuserIDValid({
        valid: false,
        focused: true
      });
      return;
    }
    setuserIDValid({
      valid: true,
      focused: true
    });
    setPaymentError(false);
  }

  const handleChangeNotices = (event: ChangeEvent<HTMLInputElement>) => {
		setNotices({...notices, [event.target.name]: event.target.checked});
	};

  const handlePayment = async (event: any) => {
    event.preventDefault();
    await setDataSend(true);
    if(dataPaymentInitial.insurance == 'axa'){
      if (!isValidForm && !dataPaymentInitial) {
        return;
     }
      let arrayNombre = cardState.name.trim().split(' ');
      let apellidoMaterno = arrayNombre[ arrayNombre.length - 1 ];
      let apellidoPaterno = arrayNombre[ arrayNombre.length - 2 ];

      let removeAll = arrayNombre.splice((arrayNombre.length-2), 2)

      setPaymentError(false);
      setPaymentisLoading(true);
      const deadline = promotions.find(promotion => promotion.labelPlazo === payCoverage);
      let paymentInformation: PaymentInformation = {
        paymentChannel: brandId,
        bankId,
        paymentDeadlineId: deadline.idPlazo.toString(),
        labelDeadline: deadline.labelPlazo,
        paymentMethod: deadline.formaPago,
        cardNat,
        cardBrand
      };

      let cardInformation : AxaCardInformation = {
        tipoTarjeta: 1,
        numeroTarjeta: cardState.number.replaceAll(' ', ''),
        mesVencimiento: cardState.expiry.substring(0,2),
        anioVencimiento: `${cardState.expiry.substring(3)}`,
        nombre: arrayNombre.join(' '),
        apellidoPaterno: apellidoPaterno,
        apellidoMaterno: apellidoMaterno,
        codigoSeguridad: cardState.cvc,
      }

      const legalAgreements = [
        {
            agreement: "aviso de confidencialidad",
            acceptance: notices.confidentiality
        },
        {
            agreement: "aviso de privacidad",
            acceptance: notices.privacy
        },
        {
            agreement: "aviso x",
            acceptance: notices.noticeX
        },
        {
            agreement: "aviso y",
            acceptance: notices.noticeY
        },
        {
            agreement: "aviso z",
            acceptance: notices.noticeZ
        }
      ]
      // console.log(cardInformation, ' ', cardState.expiry);
      const cardEncrypted = encryptRsaWithPublicKey(JSON.stringify(cardInformation));
      if (cardEncrypted) {
        let dataPaymentReq: PaymentRequest = {
          emissionDocumentId: dataPaymentInitial.emissionId,
          quoteDocumentId: dataPaymentInitial.quoteId,
          paymentDocumentId: dataPaymentInitial.paymentId,
          cardInformation: cardEncrypted,
          paymentInformation,
          dataPaymentAditional,
          business: dataPaymentInitial.business || 'cocheseguro',
          tracking_id: dataPaymentInitial.tracking_id,
          insurance: dataPaymentInitial.insurance,
          legalAgreements
        }
        // console.log(dataPaymentReq);
        sendPayment(JSON.stringify(dataPaymentReq));
        // setPaymentisLoading(false);
      } else {
        setPaymentisLoading(false);
      }

    }
    else{
      if (!isValidForm && !dataPaymentInitial) {
        return;
      }
      setPaymentError(false);
      setPaymentisLoading(true);
      const deadline = promotions.find(promotion => promotion.labelPlazo === payCoverage);
      let paymentInformation: PaymentInformation = {
        paymentChannel: brandId,
        bankId,
        paymentDeadlineId: deadline.idPlazo.toString(),
        labelDeadline: deadline.labelPlazo,
        paymentMethod: deadline.formaPago,
        cardNat,
        cardBrand
      };

      let cardInformation: CardInformation = {
        holder: cardState.name,
        cardNumber: cardState.number.replaceAll(' ', ''),
        css: cardState.cvc,
        expirationMonth: cardState.expiry.substring(0,2),
        expirationYear: `20${cardState.expiry.substring(3)}`
      }

      const legalAgreements = [
        {
            agreement: "aviso de confidencialidad",
            acceptance: notices.confidentiality
        },
        {
            agreement: "aviso de privacidad",
            acceptance: notices.privacy
        },
        {
            agreement: "aviso x",
            acceptance: notices.noticeX
        },
        {
            agreement: "aviso y",
            acceptance: notices.noticeY
        },
        {
            agreement: "aviso z",
            acceptance: notices.noticeZ
        }
      ]
      // console.log(cardInformation, ' ', cardState.expiry);
      const cardEncrypted = encryptRsaWithPublicKey(JSON.stringify(cardInformation));
      if (cardEncrypted) {
        let dataPaymentReq: PaymentRequest = {
          emissionDocumentId: dataPaymentInitial.emissionId,
          quoteDocumentId: dataPaymentInitial.quoteId,
          paymentDocumentId: dataPaymentInitial.paymentId,
          cardInformation: cardEncrypted,
          paymentInformation,
          dataPaymentAditional,
          business: dataPaymentInitial.business || 'cocheseguro',
          tracking_id: dataPaymentInitial.tracking_id,
          insurance: dataPaymentInitial.insurance,
          legalAgreements
        }
        sendPayment(JSON.stringify(dataPaymentReq));
        // setPaymentisLoading(false);
      } else {
        setPaymentisLoading(false);
      }
    }
  }

  const isValidName = (): boolean => {
    return (!!cardState.name && cardState.name.length > 3) && nameValid.valid;
  }

  const isValidEmail = (): boolean => {
    return (!!userID && userID.length > 5) && userIDValid.valid;
  }

  const isInvalidName = (): boolean => {
    return (/^[a-zA-Z\ áéíóúÁÉÍÓÚñÑ\s]*$/.test(cardState.name.trim()));
  }

  const isValidCardNumber = (): boolean => {
    return (!!cardState.number && validateCreditCardNumber(cardState.number) && cardValid);
  }
  
  const isValidExpiry = (): boolean => {
    return ( !!cardState.expiry && validateExpiry(cardState.expiry));
  }
  
  const isValidCvc = (): boolean => {
    return (!!cardState.cvc && (cardState.cvc.length >= 3 || cardState.cvc.length >= 4));
    // return ( !!cardState.cvc && validateCvc(cardState.cvc, cardState.number ? cardState.number : undefined ));
  }

  const isDataSend = (): boolean => {
    return dataSend;
  }

  const isValidForm = (): boolean => {
    if (!isValidName()) {
      return false;
    } 
    else if (!isValidCardNumber()) {
      return false;
    } else if (!isValidExpiry()) {
      // console.log('2');
      return false;
    } else if (!isValidCvc()) {
      return false;
    } else if ( Object.values(notices).includes(false)) {
      return false;
    }
    return true;
  }

  const sendRedirectError = (policy: string, message: string) => {
    setTimeout(() => {
      handlePaymentSuccess(policy,true,message)
    },5000);
  }

  const sendPayment = (data: string) => {
    const url = `${interServicesurl}${dataPaymentInitial.business}/${dataPaymentInitial.insurance}/payment`;
    fetch(url, {
      method: 'POST',
      body: data,
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .then(response => {
      console.log('Payment ',response);
      if (dataPaymentInitial.insurance === 'aig') {
        if (response.code !== 200 || !response.data || !response.success) {
          console.error('Error:')
          setPaymentisLoading(false);
          setDataSend(false);
          handlePaymentError(`Error: Error al procesar el pago.`);
          sendRedirectError(dataPaymentInitial.policy_id,response.message);
          return;
        }
        // console.log(response);
        sendPostEmission();
      } else if (dataPaymentInitial.insurance === 'gnp'){
        if (response.code !== 200 || !response.data || !response.success) {
          console.error('Error:')
          setPaymentisLoading(false);
          setDataSend(false);
          handlePaymentError(`Error: Error al procesar el pago.`);
          sendRedirectError(dataPaymentInitial.policy_id,response.message);
          return;
        }
        if (response.data.html !== '' && response.data.html !== null && response.data.html !== undefined){
          let wind = window.open("", "VALIDATION_3DSECURE", "menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes");
          wind?.document.write(response.data.html);
          setTimeout(() => {
            sendNotificationPayment();
            setPaymentisLoading(false);
            handlePaymentSuccess('');
          },30000);
        }
        sendNotificationPayment();
      } else if(dataPaymentInitial.insurance === 'primeroseguros') {
        if (response.code !== 200 || !response.data || !response.success) {
          setPaymentisLoading(false);
          setDataSend(false);
          handlePaymentError(`Error: Error al procesar el pago.`);
          sendRedirectError(dataPaymentInitial.policy_id,response.message);
          return;
        }
        sendPostEmission();
        //Implement logic for other insurance
        setPaymentisLoading(false);
        // handlePaymentSuccess('');

      } else if (dataPaymentInitial.insurance === 'axa'){
        if (response.code !== 200 || !response.data || !response.success) {
          console.error('Error:', JSON.stringify(response))
          setPaymentisLoading(false);
          setDataSend(false);
          handlePaymentError(`Error: Error al procesar el pago.`);
          sendRedirectError(dataPaymentInitial.policy_id,response.message);
          return;
        }
        sendPostEmission();
      } else {
        if (response.code !== 200 || !response.data || !response.success) {
          console.error('Error:')
          setPaymentisLoading(false);
          setDataSend(false);
          handlePaymentError(`Error: Error al procesar el pago.`);
          sendRedirectError(dataPaymentInitial.policy_id,response.message);
          return;
        }
        sendNotificationPayment();
      }

    }).catch(error => {
      setPaymentisLoading(false);
      console.error('Error:', error)
      setDataSend(false);
      handlePaymentError('Error al procesar el pago.');
      sendRedirectError(dataPaymentInitial.policy_id,error);
    });

  }

  const sendPostEmission = ()=> {
    const data = {
      emissionDocumentId: dataPaymentInitial.emissionId,
      quoteDocumentId: dataPaymentInitial.quoteId,
      paymentDocumentId: dataPaymentInitial.paymentId,
      bankName: bankInfo?.bankName ?bankInfo?.bankBrand :'',
      cardType: bankInfo?.bankBrand ?bankInfo?.bankBrand :'',
      bankCode: bankId ?bankId :'',
      business: dataPaymentInitial.business || "cocheseguro",
      insurance: dataPaymentInitial.insurance,
      tracking_id: dataPaymentInitial.tracking_id,
    }

    const url = `${interServicesurl}${dataPaymentInitial.business}/${dataPaymentInitial.insurance}/post-emission`;
    const paymentGM  = localStorage.getItem('paymentGM');
    const data_paymentGM = JSON.parse(paymentGM || '{}');
    const dollarFormat = new Intl.NumberFormat('en-US');
    changeNameEvent(dataPaymentAditional.business);
    if(dataPaymentInitial.business.toLowerCase() === 'interify'){
      console.log("Data Interify: ",{event: dataLayerInitial,boton_contacto: "Ya Quiero Pagar", plan: data_paymentGM?.plan === "AMP" ?"Plan Amplio" :data_paymentGM?.plan === "LIMIT" ?"Plan Limitado" :"Plan Basico",
      modalidad_pago: payCoverage.charAt(0).toUpperCase() + payCoverage.slice(1).toLowerCase(),
      aseguradora: dataPaymentAditional.insurance,
      total_pago: dollarFormat.format(data_paymentGM?.costo_anual) || "",
      correo: userID });
      (window as any).dataLayer.push({
        event : dataLayerInitial.event,
        boton_contacto: "Ya Quiero Pagar",
        plan: data_paymentGM?.plan == "AMP" ?"Plan Amplio" :data_paymentGM?.plan == "LIMIT" ?"Plan Limitado" :"Plan Basico",
        modalidad_pago: payCoverage.charAt(0).toUpperCase() + payCoverage.slice(1).toLowerCase(),
        aseguradora: dataPaymentAditional.insurance,
        total_pago: dollarFormat.format(data_paymentGM?.costo_anual) || "",
        correo: userID
      });
    }
    else{
      (window as any).dataLayer.push({
        event : dataLayerInitial.event,
        boton_pagar: "Ya Quiero Pagar",
        no_cotizacion : "",
        plan: data_paymentGM?.plan == "AMP" ?"Plan Amplio" :data_paymentGM?.plan == "LIMIT" ?"Plan Limitado" :"Plan Basico",
        modalidad_pago: payCoverage.charAt(0).toUpperCase() + payCoverage.slice(1).toLowerCase(),
        aseguradora: dataPaymentAditional.insurance,
        costo_anual: dollarFormat.format(data_paymentGM?.costo_anual) || "",
      });
    }

    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .catch(error => {
      setPaymentisLoading(false);
      setDataSend(false);
      handlePaymentError('Error al procesar el pago.');
      return;
    })
    .then(response => {
      setPaymentisLoading(false);
      if (!response.data || !response.success) {
        console.error('Error:', JSON.stringify(response))
        setDataSend(false);
        handlePaymentError(`Error: ${response.message}`);
        return;
      }
      // console.log(response.data.numeroPoliza);
      handlePaymentSuccess(response.data?.numeroPoliza || response.data?.policy_id || "");
    });
  }

  const sendAxaPostEmission = (params : any) => {
    const data = {
      emissionDocumentId: dataPaymentInitial.emissionId,
      quoteDocumentId: dataPaymentInitial.quoteId,
      paymentDocumentId: paymentId,
      bankName: bankInfo.bankName,
      cardType: bankInfo.bankBrand,
      bankCode: bankId,
      business: "cocheseguro",
      insurance: 'axa',
      tracking_id: dataPaymentInitial.tracking_id,
    }

    const url = `${interServicesurl}cocheseguro/${dataPaymentInitial.insurance}/post-emission`;
    
    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .catch(error => {
      setPaymentisLoading(false);
      // console.error('Error:', error)
      handlePaymentError('Error al procesar el pago.');
    })
    .then(response => {
      setPaymentisLoading(false);
      if (!response.data || !response.success) {
        console.error('Error:')
        handlePaymentError(`Error: ${response.message}`);
        return;
      }
      // console.log(response.data.numeroPoliza);
      handlePaymentSuccess(response.data.numeroPoliza)
    });
  }

  const sendNotificationPayment = () => {
    let data = dataPaymentInitial;
    const url = `${interServicesurl}${dataPaymentInitial.business}/${dataPaymentInitial.insurance}/payment-notification`;
    const paymentGM  = localStorage.getItem('paymentGM');
    const data_paymentGM = JSON.parse(paymentGM || '{}');
    const dollarFormat = new Intl.NumberFormat('en-US');
    changeNameEvent(dataPaymentAditional.business);
    if(dataPaymentInitial.business.toLowerCase() === 'interify'){
      console.log("Data Interify: ",{event: dataLayerInitial,boton_contacto: "Ya Quiero Pagar", plan: data_paymentGM?.plan === "AMP" ?"Plan Amplio" :data_paymentGM?.plan === "LIMIT" ?"Plan Limitado" :"Plan Basico",
      modalidad_pago: payCoverage.charAt(0).toUpperCase() + payCoverage.slice(1).toLowerCase(),
      aseguradora: dataPaymentAditional.insurance,
      total_pago: dollarFormat.format(data_paymentGM?.costo_anual) || "",
      correo: userID });
      
      (window as any).dataLayer.push({
        event : dataLayerInitial.event,
        boton_contacto: "Ya Quiero Pagar",
        plan: data_paymentGM?.plan == "AMP" ?"Plan Amplio" :data_paymentGM?.plan == "LIMIT" ?"Plan Limitado" :"Plan Basico",
        modalidad_pago: payCoverage.charAt(0).toUpperCase() + payCoverage.slice(1).toLowerCase(),
        aseguradora: dataPaymentAditional.insurance,
        total_pago: dollarFormat.format(data_paymentGM?.costo_anual) || "",
        correo: userID
      });
    }
    else{
      (window as any).dataLayer.push({
        event : dataLayerInitial.event,
        boton_pagar: "Ya Quiero Pagar",
        no_cotizacion : "",
        plan: data_paymentGM?.plan == "AMP" ?"Plan Amplio" :data_paymentGM?.plan == "LIMIT" ?"Plan Limitado" :"Plan Basico",
        modalidad_pago: payCoverage.charAt(0).toUpperCase() + payCoverage.slice(1).toLowerCase(),
        aseguradora: dataPaymentAditional.insurance,
        costo_anual: dollarFormat.format(data_paymentGM?.costo_anual) || "",
      });
    }


    fetch(url, {
      method: 'POST',
      body: JSON.stringify(data),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .catch(error => {
      setPaymentisLoading(false);
      // console.error('Error:', error)
      handlePaymentError('Error al procesar el pago.');
    })
    .then(response => {
      setPaymentisLoading(false);
      if (response.code !== 200 || !response.success) {
        console.error('Error:')
        handlePaymentError(`Error: ${response.message}`);
        return;
      }
      // console.log(response.data.numeroPoliza);
      handlePaymentSuccess(response.data.policy);
      // window.dataLayer.push({
      //   event : "Purchase",
        // currency: “MXN”,
        // transaction_ID: “Número de cotización / Póliza”,
        // value : “Value - Paso 10”,
        // coupon: “Código de promoción”,
        // tax: “Por validar”,
        // items: “1”,
        // item_id: “Por validar”,
        // item_name: “Item_name”,
        // price: “Value”,
        // user_id: “{{No Cliente CRM}}”
        // });

    });
  }

  const handlePaymentSuccess = (policy: string, error?: boolean, message?: any) => {
    const getRedirectUrl = `${interServicesurl}redirect`;
    const paymentResult = {
      result: 'Success',
      policy
    }

    fetch(getRedirectUrl, {
      method: 'POST',
      body: JSON.stringify(dataPaymentInitial),
      headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    }).then(res => res.json())
    .catch(error => {
      setPaymentisLoading(false);
      // console.error('Error:', error)
      handlePaymentError('Error al procesar el pago.');
    })
    .then(response => {
      console.log(response.data);
      setPaymentisLoading(false);
      if (!response.success) {
        console.error('Error:')
        handlePaymentError(`Error: ${response.message}`);
        return;
      }
      setPaymentAlert(false);
      setTimeout(() => {
        window.location.href = error ? `${response.data}?payment_or_emision_error=true&error_message=${message}` : `${response.data}?payment_or_emision_error=false`;
        window.parent.postMessage(JSON.stringify(paymentResult), '*');
      }, 1000);
    });

    
  }

  const handlePaymentError = (msg?: string) => {
    console.log('Error ',msg);
    const paymentResult = {
      result: 'Error'
    }
    setPaymentError(true);
    if (msg) {
      setErrorMsg(msg);
    }
    setTimeout(() => {
      // window.location.href = `${redirectUrl}?poliza=8422239821`;
      window.parent.postMessage(JSON.stringify(paymentResult), '*');
    }, 1000);
  }


  const getAccessToken = async() => {
    const url = `${interServicesurl}authorization/token`;
    // const url = `${REACT_APP_SERVICE_AIG_URL}check-card`;
    const body = {
      "client_id": process.env.REACT_APP_CLIENT_ID,
      "client_secret": process.env.REACT_APP_CLIENT_SECRET
    };
    try {
      const res = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers:{
          'Content-Type': 'application/json'
        }
      }).then(res => res.json());
      if (res.success && res.data.access_token) {
        localStorage.setItem('token', res.data.access_token);
      }
    } catch (error) {
      console.error('Error: ' , error)
    }
  }

  const setStyleError = (name: string) => {
    if(name === "name" && !nameValid.valid) {
      return <WarningAmber color={"error"} />;
    } else if(name === "cvc" && !cvcValid.valid) {
      return <WarningAmber color={"error"} />;
    } else if (name === "number" && !numberValid.valid) {
      return <WarningAmber color={"error"} />;
    } else if (name === "expiry" && !expiryValid.valid) {
      return <WarningAmber color={"error"} />;
    }

    return null
  }

  function replaceCardNumber(cardNumber: string){
    if(cardNumber.length >= 16 && cardNumber.length <= 19){
      const longitud = cardNumber.length;
      const asteriscos = '*'.repeat(longitud-9);
      return `${cardNumber.substring(0, 4)}${asteriscos}${cardNumber.substring(longitud-5, longitud)}`;
    }
    if(cardNumber.length >= 20){
      const longitud = cardNumber.length;
      const asteriscos = '*'.repeat(longitud-10);
      return `${cardNumber.substring(0, 4)}${asteriscos}${cardNumber.substring(longitud-4, longitud)}`;
    }
    return cardNumber;
  }
      
  function changeNameEvent(business: string){
    switch (business.toLowerCase()) {
      case 'cocheseguro':
        setDataLayerInitial({event: `coche_seguro_${dataLayerInitial.event}`})
        break;
      case 'motosegura':
          setDataLayerInitial({event: `moto_segura_${dataLayerInitial.event}`});
        break;  
      case 'interify':
          setDataLayerInitial({ event: `ITFY_auto_emitir_datos_bancarios` });
          break;
      default:
        setDataLayerInitial({event: `coche_seguro_${dataLayerInitial.event}`});
        break;
    }
  }

  return (
    <Box
      padding={isMobile?1:isTablet?2:2}
      width={isTablet?'96%': isMobile?'100%':'100%'}
      style={{
        backgroundColor: "white",
        borderRadius: "10px",
      }}
    >
      {paymentIsLoading ? <Loading text='Realizando pago. Este proceso puede tardar unos minutos.'></Loading> : null}
      {!infoCardValid && <h3>Ha ocurrido un error al cargar el formulario de pago.</h3>}
      {infoCardValid && <form autoComplete="off" onSubmit={handlePayment}>
        
        <Box style={{backgroundColor:'white'}}>
        {paymentError ? <Alert severity="error">{errorMsg}</Alert>: null}
        {paymentAlert ? <Alert severity="success">¡Pago exitoso!</Alert>: null}
        <Grid container>
  
          <Grid item xs={12} md={6} lg={6}>
            <Box 
            display={'flex'} 
            flexDirection={'row'} 
            padding={2}
            paddingTop={4}
            >
              <Cards
                        cvc={cardState.cvc.length <= 3 ? '***' : '****'}
                        expiry={cardState.expiry}
                        focused={cardState.focused}
                        name={cardState.name}
                        number={replaceCardNumber(cardState.number)}
                        acceptedCards={['visa', 'mastercard', 'amex']}
                        placeholders={placeholders}
                    />
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <Box 
              padding={2}
              style={{backgroundColor:'white'}}
            >
                <p style={{ marginLeft:20, fontSize:14, lineHeight:1.66, fontStyle:"italic"}}>
                  Nombre en tarjeta
                </p>
                <TextField
                id="standard-basic"
                name="name"
                label="Nombre"
                value={cardState.name}
                focused={nameValid.valid && nameValid.focused ? true : undefined}
                variant="outlined"
                onChange={handleCardChange}
                onBlur={handleNameBlur}
                onFocus={handleFocus}
                error={!nameValid.valid}
                autoComplete="false"
                fullWidth
                color={cardState.name !== "" ? "success" : "primary"}
                InputProps={{
                  style: {
                    borderRadius: 8,
                    borderColor: "green"
                  },
                  endAdornment:
                  isValidName() ? <Check color={"success"} /> : setStyleError("name"),
                }}
                />
                <Box marginTop={2}>
                { dataPaymentInitial.business.toLowerCase() === 'interify' &&(<TextField
                id="standard-basic"
                name="email"
                label="email"
                value={userID}
                focused={userIDValid.valid && userIDValid.focused ? true : undefined}
                variant="outlined"
                onChange={handleEmailChange}
                onBlur={handleEmailBlur}
                onFocus={handleFocus}
                error={!userIDValid.valid}
                autoComplete="false"
                fullWidth
                color={userID !== "" ? "success" : "primary"}
                InputProps={{
                  style: {
                    borderRadius: 8,
                    borderColor: "green"
                  },
                  endAdornment:
                  isValidEmail() ? <Check color={"success"} /> : setStyleError("email"),
                }}
                />)}
                </Box>



                <Box marginTop={2}>
                <TextField
                id="standard-basic"
                label="Numero de tarjeta"
                value={cardState.number}
                variant="outlined"
                onChange={handleCardChange}
                onFocus={handleFocus}
                onBlur={handleNumberBlur}
                color={cardState.number !== "" ? "success" : undefined}
                error={!numberValid.valid}
                focused={numberValid.valid && numberValid.focused ? true : undefined}
                name="number"
                autoComplete="false"
                type={typeCardNumberF}
                fullWidth
                InputProps={{
                  endAdornment:
                  <>
                    {promotionsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {isValidCardNumber() && !promotionsLoading ? <Check color={"success"} /> : setStyleError("number")}
                  </>
                }}
                />
                </Box>
                <Box display="flex" justifyContent="space-between" marginTop={2}>
                  <TextField
                  id="standard-basic"
                  label="Vencimiento"
                  value={cardState.expiry}
                  variant="outlined"
                  size="medium"
                  onChange={handleCardChange}
                  onFocus={handleFocus}
                  onBlur={handleOnBlur}
                  color={cardState.expiry !== "" ? "success" : undefined}
                  focused={expiryValid.valid && expiryValid.focused ? true : undefined}
                  error={!expiryValid.valid}
                  name="expiry"
                  fullWidth
                  style={{ borderRadius:8, marginRight:10 }}
                  InputProps={{
                    endAdornment:
                    isValidExpiry() ? <Check color={"success"} /> : setStyleError("expiry"),
                  }}
                  />
                  <TextField
                  id="standard-basic"
                  label="Código (CVV)"
                  value={cardState.cvc}
                  variant="outlined"
                  size="medium"
                  onChange={handleCardChange}
                  onFocus={handleFocus}
                  onBlur={handleOnBlur}
                  color={cardState.cvc !== "" ? "success" : undefined}
                  focused={cvcValid.valid && cvcValid.focused ? true : undefined}
                  error={!cvcValid.valid}
                  name="cvc"
                  type={typeCvc}
                  fullWidth
                  style={{ borderRadius:8 }}
                  InputProps={{
                    endAdornment:
                    isValidCvc() ? <Check color={"success"} /> : setStyleError("cvc"),
                  }}
                  />
                </Box>
                {!hidePlazos && (<FormControl
                  style={{
                    width: `${isDesktop ? "100%" : "100%"}`,
                    marginTop: '20px',
                    marginBottom: '40px'
                  }}
                >
                  <InputLabel id="demo-simple-select-label">
                    Plazo
                  </InputLabel>
                  <Select
                    disabled={promotionsLoading}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={payCoverage}
                    label="Plazo"
                    onChange={handleChangePromotion}
                  >
                    {promotions.map((promo, i) => (
                      <MenuItem key={i} value={promo.labelPlazo}>
                        {promo.labelPlazo}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>)}
            </Box>
          </Grid>
        </Grid>
        <Box>
          {/* <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            marginBottom="24px"
          >
            <Checkbox
              checked={notices.noticeX}
              name="noticeX"
              onChange={handleChangeNotices}
              inputProps={{ "aria-label": "controlled" }}
              style={{ padding: "0px 10px 0px 0px" }}
            />
            <Typography>
              Mi vehículo no es utilizado ni será utilizado para presentar servicios de entrega por paqueteria a domicilio y/o similares.
            </Typography>
          </Box> */}
          {/* <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            marginBottom="24px"
          >
            <Checkbox
              checked={notices.noticeY}
              name="noticeY"
              onChange={handleChangeNotices}
              inputProps={{ "aria-label": "controlled" }}
              style={{ padding: "0px 10px 0px 0px" }}
            />
            <Typography>
              No he recibido indemnización alguna de parte de alguna aseguradora derivadade la perdida total de mi vehículo.
            </Typography>
          </Box> */}
          {/* <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            marginBottom="24px"
          >
            <Checkbox
              checked={notices.noticeZ}
              name="noticeZ"
              onChange={handleChangeNotices}
              inputProps={{ "aria-label": "controlled" }}
              style={{ padding: "0px 10px 0px 0px" }}
            />
            <Typography>
              Mi vehículo no se encuentra chocado o siniestrado de alguna forma.
            </Typography>
          </Box> */}
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            marginBottom="24px"
          >
            <Checkbox
              checked={notices.privacy}
              name="privacy"
              onChange={handleChangeNotices}
              inputProps={{ "aria-label": "controlled" }}
              style={{ padding: "0px 10px 0px 0px" }}
            />
            <Typography>
              He leído y acepto el <a href="https://storage.googleapis.com/inter-connect-prod.appspot.com/cocheseguro/legal/AVISO%20DE%20PRIVACIDAD.pdf" target="_blank" rel="noopener noreferrer">Aviso de privacidad.</a>
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={"row"}
            justifyContent={"flex-start"}
            alignItems={"flex-start"}
            marginBottom="24px"
          >
            <Checkbox
              checked={notices.confidentiality}
              name="confidentiality"
              onChange={handleChangeNotices}
              inputProps={{ "aria-label": "controlled" }}
              style={{ padding: "0px 10px 0px 0px" }}
            />
            <Typography>
              Estoy de acuerdo con las <a href="https://storage.googleapis.com/inter-connect-prod.appspot.com/cocheseguro/legal/TERMINOS%20Y%20CONDICIONES%20COCHE%20SEGURO.pdf" target="_blank" rel="noopener noreferrer">Condiciones Generales y Términos de Uso.</a>
            </Typography>
          </Box>
          <Button
                    disabled={!isValidForm() || dataSend}
                    variant="contained"
                    type="submit"
                    style={
                      isDesktop
                        ? { width: "100%", textTransform: "none" }
                        : { width: "100%", textTransform: "none" }
                    }
                  >
                    <span
                      style={{
                        marginRight: "7px",
                        fontSize: "18px",
                      }}
                    >
                      ¡Ya quiero pagar!
                    </span>
          </Button>
        </Box>
        <Box 
          textAlign="center" 
          padding="0px 20px"
          marginTop="20px"
          style={{backgroundColor:'white'}}
        >
          <Typography
              textAlign="center"
              fontSize={`${isDesktop ? "13px" : "16px"}`}
            >
              Por seguridad el portal de inter.mx no almacena números de tarjeta
              de crédito, la responsabilidad de la domiliciación en formas de pago
              diferidas queda en manos de cada aseguradora.
            </Typography>
          </Box>
        </Box>
        </form>
  }
      
    </Box>
  )
}
